
import axios from 'axios'
import { useToast } from "vue-toastification";

export default {
    install: (app) => {
        const axiosInstance = axios.create({
            baseURL: process.env.VUE_APP_API_URL + '/api/',
            withCredentials: true
        });

        axiosInstance.interceptors.request.use((request) => {
            request.config = {
                showMessageToast: true, // may be overwritten in next line
                ...(request.config || {}),
            };
            
            let storageOption = localStorage.getItem('customerStorage') === "localStorage" ? localStorage : sessionStorage;
            if(storageOption.getItem('token') !== null) {
                request.headers.Authorization = 'Bearer ' + storageOption.getItem('token');
            }

            return request;
        }, (error) => {
            console.log(error);
            return Promise.reject(error);
        });

        axiosInstance.interceptors.response.use(function (response) {
            if(response.config.config.showMessageToast && (typeof response.data.message === 'string' || response.data.message instanceof String)) {
                const toast = useToast();
                toast.success(response.data.message);
            }
            
            return response;
        }, function (error) {            
            //Check if token is expired a route the user to login screen
            if (error.response.status === 401 || error.response.status === 403) {
                localStorage.clear();
                sessionStorage.clear();

                window.location.href = "/login";
                
                return true;
            }
        
            const toast = useToast();
            if(error.response.data?.errors) {
                
                if(Array.isArray(error.response.data?.errors)) {
                    error.response.data?.errors.forEach(function(error) {
                        toast.error(error, { timeout: false });
                    });
                } else if(typeof error.response.data?.errors === 'object') {
                    Object.values(error.response.data?.errors).forEach(function(error) {
                        if(Array.isArray(error)) {
                            error.forEach(function(error) {
                                toast.error(error, { timeout: false });
                            });
                        } else {
                            toast.error(error, { timeout: false });
                        }
                    });
                }
            } else if(typeof error.response.data?.message === 'string' || error.response.data?.message instanceof String) {
                toast.error(error.response.data.message, { timeout: false });
            } else {
                toast.error('Er is iets mis gegaan', { timeout: false });
            }
            
            return Promise.reject(error);
        });

        app.config.globalProperties.$http = axiosInstance
    }
}
