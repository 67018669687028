<template>
    <div :class="['modal-container p-3', additional_class]" @click="closeSelf">
        <div :class="['bg-white rounded-2 modal-idesign']">
            <img src="@/assets/logo-zelfstandig-ondernemers.svg" class="bg-icon">
            <div class="p-4 mt-0 mt-md-5">
                <h2 class="m-0 fw-bold">{{ title }}</h2>
            </div>
            <hr class="mt-0"/>
            <div class="p-4 pt-1">
                <p class="m-0">{{ content }}</p>
                <div class="mt-2" v-if="modal_type === this.$ModalType.Confirmation">
                    <router-link :to="url" class="btn btn-primary"><i :class="['fa me-2', button_icon]"></i>{{ button_text }}</router-link>
                </div>
                <div class="mt-2" v-if="modal_type === this.$ModalType.Notification">
                    <div @click="() => closeSelf()" class="btn btn-primary"><i class="fa fa-times me-2"></i>Sluiten</div>
                </div>
                <div class="mt-2" v-if="modal_type === this.$ModalType.Question">
                    <div @click="() => performAction(question_enum)" class="btn btn-primary me-1"><i class="fa fa-check me-2"></i>{{ button_text || "Ja" }}</div>
                    <div @click="closeSelf" :to="url" class="btn btn-danger"><i class="fa fa-times me-2"></i>Annuleren</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
import $ from 'jquery';

export default {
    name: 'modal-component',
    props: ['title', 'content', 'button_text', 'button_icon', 'url', 'modal_type', 'question_enum', 'additional_class'],
    methods: {
        closeSelf() {
            this.$emit('closeModal');
        },
        performAction(action) {
            this.$emit(action);
        }
    },
    mounted() {
        $(".modal-container").fadeOut(0);
        $(".modal-container").fadeIn(500);
    },
}
</script>

<style scoped>
.modal-container {
    width: 100vw;
    height: 110vh;

    overflow: hidden;

    position: fixed;
    top: 0;
    left: 0;
    
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 16000;
}

.modal-idesign {
    width: 100%;
    max-width: 720px;
    margin: auto;
    animation: appear 500ms forwards;
}

.bg-icon {
    position: absolute;
    left: 0;
    bottom: 24px;
    opacity: 0.03;
    z-index: 1;
    width: 640px;
}

@keyframes appear {
    0% {
        opacity: 0;
        transform: translateY(-16px);
    }

    100% {
        opacity: 1;
        transform: 0;
    }
}

.disappear {
    animation: disappear-anim 500ms ease-in-out forwards;
}

@keyframes disappear-anim {
    0% {
        opacity: 1;
        transform: 0;
    }
    
    100% {
        opacity: 0;
        transform: translateY(-16px);
    }
}
</style>