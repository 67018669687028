<template>
  <div class="relative flex flex-col items-center justify-center min-h-screen">
    Please wait
  </div>
</template>

<script type="text/javascript">

import { setCustomer } from '@/App.vue';
import $ from "jquery";

export default {
    name: 'ImpersonatePage',
    async mounted() {
        const params = new URL(location.href).searchParams;

        try {
            localStorage.clear();
            sessionStorage.clear();

            const response = await this.$http.get('/impersonate', {
                headers: {
                    Authorization: 'Bearer ' + params.get('token')
                }
            });

            localStorage.setItem('customerStorage', 'sessionStorage')
            sessionStorage.setItem('token', response.data.access_token);
            setCustomer(response.data.customer);

            await this.$http.get('/dashboard')
                .then((response) => {
                    // Deze functie verzorgt de opmaak van het hamburgermenu met een bolletje en het aantal notificiaties voor de gebruiker
                    this.$hasActions(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
            
            $(".hamburger-authentication").show();
            
            this.emitter.emit('login', true);

            await this.$router.push({ path: '/' });
        } catch (error) {
            console.error(error)

            localStorage.clear();
            sessionStorage.clear();
            this.emitter.emit('login', false)
            // await this.$router.push({ path: '/login' });
        }
    }
};
</script>
