import { h } from "vue";
import { RouterLink } from 'vue-router'

export default {
  functional: true,
  props: {
        tag: { type: String, required: false, default: "p" },
        text: { type: String, required: true },
        class: { type: String, required: false, default: "" }
  },
  setup(props) {
    let inlineRouterRegex = new RegExp('\\[(.+)\\]\\(' + window.location.origin + '([^\\s]+)\\)');
    let inlineLinkRegex = new RegExp('\\[(.+)\\]\\((https?://[^\\s]+)\\)');

    let routerRegex = new RegExp(window.location.origin + '(/[^\\s]+)');
    let linkRegex = new RegExp('(https?://[^\\s]+)');

    return () =>
        h(
            props.tag,
            {
                class: props.class
            },
            props.text
                .split("\n")
                .reduce((accumulator, string) => {
                    let parts = [];

                    do {
                        let match = null;
                        switch (true) {
                            case inlineRouterRegex.test(string):
                                match = inlineRouterRegex.exec(string);

                                if(match.index) {
                                    parts.push(string.substring(0, match.index));
                                }

                                parts.push(h(RouterLink, { to: match[2], class: 'btn btn-primary btn-sm' }, () => match[1]));

                                string = string.substring(match.index + match[0].length);
                                break;
                            case inlineLinkRegex.test(string):
                                match = inlineLinkRegex.exec(string);

                                if(match.index) {
                                    parts.push(string.substring(0, match.index));
                                }

                                parts.push(h('a', { href: match[2], class: 'btn btn-primary btn-sm', target: '_blank' }, match[1]));

                                string = string.substring(match.index + match[0].length);

                                break;
                                
                            case routerRegex.test(string):
                                match = routerRegex.exec(string);

                                if(match.index) {
                                    parts.push(string.substring(0, match.index));
                                }

                                parts.push(h(RouterLink, { to: match[1], class: 'btn btn-primary btn-sm' }, () => match[0]));

                                string = string.substring(match.index + match[0].length);
                                break;
                            case linkRegex.test(string):
                                match = linkRegex.exec(string);

                                if(match.index) {
                                    parts.push(string.substring(0, match.index));
                                }

                                parts.push(h('a', { href: match[1], class: 'btn btn-primary btn-sm', target: '_blank' }, match[0]));

                                string = string.substring(match.index + match[0].length);

                                break;
                            default:
                                if(string != '') {
                                    parts.push(string);
                                }
                                string = '';
                        }
                    } while(string.length);


                    if(parts.length) {
                        if (!Array.isArray(accumulator)) {
                            return [accumulator, h("br"), ...parts];
                        }
                        return accumulator.concat([h("br"), ...parts]);
                    }
                }, [])
        );
    }
};